import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';

import {
  graphQlBrowseApiRef,
  GraphQLEndpoints,
} from '@backstage/plugin-graphiql';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: graphQlBrowseApiRef,
    deps: {},
    factory: () =>
      GraphQLEndpoints.from([
        // Use the .create function if all you need is a static URL and headers.
        GraphQLEndpoints.create({
          id: 'pluto',
          title: 'Pluto',
          url: 'https://alpaca.rocketseat.com.br/pluto/graphql',
          // Optional extra headers
          // headers: { Extra: 'Header' },
        }),
      ]),
  }),
  ScmAuth.createDefaultApiFactory(),
];
